import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Specialisation from "../components/Specialisation";
import HeroSmall from "../components/HeroSmall";
import hero from "../../static/specialisaties.jpg";
import electrotherapie from "../../static/electrotherapie.jpg";
import dryNeedle1 from "../../static/dry-needle-1.jpg";
import dryNeedle2 from "../../static/dry-needle-2.jpg";
import dryNeedle3 from "../../static/dry-needle-3.jpg";
import manueleTherapie1 from "../../static/manuele-therapie-1.jpg";
import manueleTherapie2 from "../../static/manuele-therapie-2.jpg";
import oefentherapie1 from "../../static/oefentherapie-1.jpg";
import oefentherapie2 from "../../static/image4.jpg";
import oefentherapie3 from "../../static/oefentherapie-3.jpg";
import rug1 from "../../static/rug1.jpg";
import rug2 from "../../static/image2.jpg";
import postop1 from "../../static/postop1.jpg";
import postop2 from "../../static/postop2.jpg";
import pelv1 from "../../static/pelv1.jpg";
import pelv2 from "../../static/pelv2.jpg";
import pelv3 from "../../static/pelv3.jpg";
import perinat1 from "../../static/perinat1.jpg";
import perinat2 from "../../static/perinat2.jpg";

const SpecialisationsPage = ({ location, data }) => {
  const url = location.pathname ? location.pathname : "";

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Specialisaties – Kine-Osteo Hagers</title>
        <meta name="description" content="Bekijk al onze specialisaties" />
      </Helmet>
      <Layout routeUrl={url}>
        <section className="section section--no-mt section--no-mb">
          <HeroSmall title="Specialisaties" hero={hero} />
        </section>
        <Specialisation
        altClass="section--light"
          image={manueleTherapie1}
          specialisation2={
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={manueleTherapie2} alt="" />
              </div>
            </div>
          }
          title="Manuele therapie"
          desc="Manuele therapie is een specialisatie binnen de kinesitherapie waarbij de beweeglijkheid van het menselijk lichaam wordt geoptimaliseerd of hersteld. Deze technieken komen vooral van pas bij blokkades in de wervelzuil en in het bekken. Wanneer de hinderende blokkades zijn verholpen kan de patiënt het optimale bewegingspatroon verder gaan versterken aan de hand van een individueel aangepast oefenschema."
        />
        <Specialisation
          image={postop1}
          title="Postoperatieve revalidatie"
          desc="We behandelen patiënten na orthopedische ingrepen (heup-, knie- en schouderprothesen, fracturen, artroscopieën,…) en operaties van de organen (hart, longen, organen van het kleine bekken..) om zowel de pijn te verminderen, als het dagdagelijks functioneren te herstellen."
          specialisation2={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={postop2} alt="" />
              </div>
            </div>,
          ]}
        />
        <Specialisation
          image={oefentherapie1}
          altClass="section--light"
          title="Medische trainingstherapie"
          desc="We begeleiden zowel beginnende als ervaren sporters in hun revalidatie, met als doel een medisch verantwoorde sporthervatting."
          specialisation2={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={oefentherapie2} alt="" />
              </div>
            </div>,
          ]}
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={oefentherapie3} alt="" />
              </div>
            </div>,
          ]}
        />
        <Specialisation
          title="Respiratoire kinesitherapie"
          desc="Autogene drainagetechnieken worden toegepast bij patiënten die moeite hebben sputa (slijmen) op te hoesten, bv. bij RSV bij baby's en jonge kinderen, COPD, mucoviscidose, na een operatieve ingreep... Mensen met acute en/of chronische hyperventilatie helpen we verder met specifieke ademhalingsoefeningen en adviezen."
        />
        <Specialisation
          image={pelv1}
          altClass="section--light"
          title="Pelvische reëducatie"
          desc="Pelvische reëducatie wilt letterlijk zeggen: het (her)opvoeden van het kleine bekken. Mannen, vrouwen en kinderen met problemen van urine- en/of stoelgangsverlies, chronische blaasontstekingen, obstipatie, orgaanzakkingen, pijn in het kleine bekken, seksuele problematieken... komen hier vaak voor in aanmerking. Enerzijds bekijken we de werking van de organen in het kleine bekken (blaas, darm, prostaat of baarmoeder), anderzijds de werking van de bekkenbodemspieren."
          specialisation2={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={pelv2} alt="" />
              </div>
            </div>,
          ]}
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={pelv3} alt="" />
              </div>
            </div>,
          ]}
        />
        <Specialisation
          image={perinat1}
          title="Perinatale kinesitherapie"
          desc="Deze behandelingen richten zich specifiek op de zwangere en pas bevallen mama’s. Enerzijds worden jonge moeders zo goed mogelijk voorbereid op hun bevalling, anderzijds zorgen we voor een optimale revalidatie achteraf. Ook kunnen we ongemakken die zich in die periode stellen (zoals bekkenpijn, lage rugpijn, …) behandelen. We werken nauw samen met de collega’s uit de praktijk, maar ook met de vroedvrouwen uit de omgeving."
          specialisation2={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={perinat2} alt="" />
              </div>
            </div>,
          ]}
        />
        <Specialisation
          altClass="section--light"
          image={dryNeedle1}
          specialisation2={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={dryNeedle2} alt="" />
              </div>
            </div>,
          ]}
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={dryNeedle3} alt="" />
              </div>
            </div>,
          ]}
          title="Myofaciale therapie: dry needling"
          desc="Dry needling is een specialisatie binnen de kinesitherapie die wordt gebruikt bij pijnklachten komende van gespannen spieren. Tijdens de behandeling wordt een dun naaldje in de te behandelen spier(en) ingebracht om zo op een efficiënte manier de spier te laten ontspannen. Dry needling kan zo gebruikt worden in een breed spectrum van klachten: hoofdpijn, nek- en rugpijn, spiergerelateerde klachten ten gevolge van stress of overbelasting, duizeligheid, ..."
        />
        <Specialisation
          title="Elektrotherapie"
          desc="Deze techniek wordt vooral toegepast bij neurologische klachten (oa zenuwuitval) en pijndemping (Ultrason en TENS)."
        />
        <Specialisation
          image={rug1}
          altClass="section--light"
          title="Rug- en nekschool"
          desc="We begeleiden patiënten met nek- en rugproblematieken, door hen enerzijds manueel te behandelen om de eerste klachten te verhelpen. Nadien starten we een oefenprogramma op om de rug- en nekspieren te versterken, zodat de pijnklachten niet meer terug komen. Verder geven we adviezen m.b.t. heffen en tillen, houding, ergonomie, stretch-oefeningen…"
          specialisation2={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={rug2} alt="" />
              </div>
            </div>,
          ]}
        />
      </Layout>
    </div>
  );
};

export default SpecialisationsPage;
